import React, { FC } from 'react';

import { SearchFieldMenuListProps } from 'components/dedicated/SearchField/SearchField.types';
import Box from 'components/core/Box/Box';
import Spinner from 'components/core/Spinner/Spinner';
import Text from 'components/core/Text/Text';

import styles from '../SearchField.module.scss';

export const SearchFieldMenuList: FC<SearchFieldMenuListProps> = ({ children, selectProps }) => (
  <Box className={styles.searchSuggestionResultList} padding={4}>
    {selectProps.isLoading ? (
      <Spinner />
    ) : (
      <>
        {selectProps.options.length > 0 && (
          <Text marginBottom={4.4} marginLeft={5} marginTop={0.5} variant='sectionHeaderStandard'>
            Best Matches Found
          </Text>
        )}
        <Box alignItems='flex-start' display='flex' flexDirection='column' gap={1}>
          {children}
        </Box>
      </>
    )}
  </Box>
);
