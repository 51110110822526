import React, { FC } from 'react';
import cx from 'classnames';

import { SearchFieldControlProps } from 'components/dedicated/SearchField/SearchField.types';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import Icon from 'components/core/Icon/Icon';

import styles from '../SearchField.module.scss';

export const SearchFieldControl: FC<SearchFieldControlProps> = ({ children, selectProps }) => {
  const { onCancelSearch, isSuggestionsVisible, inputValue, selectedSuggestion } = selectProps;
  return (
    <Box
      className={cx(
        styles.searchFieldContainer,
        isSuggestionsVisible && styles.searchFieldContainerWithResults,
      )}
      display='flex'
      style={{ width: '100%' }}
    >
      <Icon
        className={styles.searchIcon}
        iconName='searchThinnerStroke'
        marginLeft={1}
        marginTop={1}
        size={2.6}
      />
      {children}

      {(selectedSuggestion || inputValue) && (
        <Button marginTop={2} onClick={() => onCancelSearch()} variant='icon'>
          <Icon className={styles.clearSearchButton} iconName='cancel' size={2.6} />
        </Button>
      )}
    </Box>
  );
};
