import { debounce } from 'lodash';
import AsyncSelect from 'react-select/async';
import React, { FC, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { NoSearchResultsMessage } from 'components/dedicated/SearchField/components/NoSearchResultsMessage';
import { SearchFieldControl } from 'components/dedicated/SearchField/components/SearchFieldControl';
import { SearchFieldMenuList } from 'components/dedicated/SearchField/components/SearchFieldMenuList';
import { SearchFieldOption } from 'components/dedicated/SearchField/components/SearchFieldOption';
import {
  SearchFieldProps,
  SuggestionItem,
} from 'components/dedicated/SearchField/SearchField.types';
import Box from 'components/core/Box/Box';
import removeApiIdPrefixes from 'utils/removeApiIdPrefixes';

import styles from './SearchField.module.scss';

const SearchField: FC<SearchFieldProps> = ({
  placeholder,
  onChange,
  searchSuggestions,
}: SearchFieldProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSuggestion, setSelectedSuggestion] = useState<SuggestionItem | null>();
  const [hideSuggestionList, setHideSuggestionList] = useState(false);
  const searchBoxDivRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestionList, setSuggestionList] = useState<SuggestionItem[]>([]);
  const [canCacheOptions, setCanCacheOptions] = useState(false);

  const onCancelSearch = () => {
    setSearchTerm('');
    setSelectedSuggestion(null);
    onChange(null);
  };

  const onSelectedItem = (suggestion: SuggestionItem) => {
    const splittedTerm = removeApiIdPrefixes(suggestion.id);

    setSearchTerm(splittedTerm);
    setSelectedSuggestion(suggestion);
    onChange(suggestion);
  };

  const onSearchInputChange = (value: string) => {
    setSearchTerm(value);
    setSelectedSuggestion(undefined);
    setHideSuggestionList(false);
  };

  const handleClickOutside = (event: any) => {
    if (searchBoxDivRef.current && !searchBoxDivRef.current.contains(event.target)) {
      setHideSuggestionList(true);
    }
  };

  const debouncedFetchOptions = debounce(async (inputValue, resolve) => {
    setIsLoading(true);
    const options = await searchSuggestions(inputValue);

    setSuggestionList(options as SuggestionItem[]);
    setIsLoading(false);

    resolve(options);
  }, 200);

  const loadOptions = (inputValue: string): Promise<SuggestionItem[]> => {
    setSearchTerm(inputValue);
    return new Promise(resolve => {
      debouncedFetchOptions(inputValue, resolve);
    });
  };

  const onEnterPress = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      const firstSuggestion = suggestionList[0];

      const suggestion: SuggestionItem = {
        attributes: {},
        icon: undefined,
        id: firstSuggestion ? firstSuggestion.id : searchTerm,
        subtitle: '',
        title: '',
      };

      onChange(suggestion);
      setSelectedSuggestion(suggestion);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onSelectFocus = async () => {
    if (searchTerm) {
      setIsLoading(true);
      setHideSuggestionList(false);
      setIsLoading(false);
      setCanCacheOptions(false);
    }
  };
  const isSuggestionsVisible = !!searchTerm && !selectedSuggestion && !hideSuggestionList;

  return (
    <div ref={searchBoxDivRef} style={{ display: 'flex', flexGrow: 1 }}>
      <Box className={styles.searchContainer}>
        <AsyncSelect
          cacheOptions={canCacheOptions}
          className={cx(
            styles.selectContainer,
            isSuggestionsVisible && styles.selectContainerWithResults,
          )}
          closeMenuOnSelect
          components={{
            Control: SearchFieldControl,
            DropdownIndicator: null,
            LoadingIndicator: undefined,
            MenuList: SearchFieldMenuList,
            Option: SearchFieldOption,
          }}
          defaultOptions={suggestionList}
          inputValue={searchTerm}
          isLoading={isLoading}
          isSearchable
          loadOptions={loadOptions}
          menuIsOpen={isSuggestionsVisible}
          noOptionsMessage={NoSearchResultsMessage}
          onBlur={() => setCanCacheOptions(true)}
          onChange={val => onSelectedItem(val as SuggestionItem)}
          onFocus={() => onSelectFocus()}
          onInputChange={(value, actionMeta) =>
            actionMeta.action === 'input-change' && onSearchInputChange(value)
          }
          placeholder={placeholder}
          styles={{
            input: base => ({
              ...base,
              display: 'flex',
              justifyContent: 'stretch',
              opacity: '1 !important',
              visibility: 'visible',
            }),
            option: base => ({
              ...base,
              padding: 0,
            }),
          }}
          tabIndex={0}
          value={selectedSuggestion}
          {...{
            isSuggestionsVisible,
            onCancelSearch,
            searchTerm,
            selectedSuggestion,
          }}
          onKeyDown={event => onEnterPress(event)}
        />
      </Box>
    </div>
  );
};

export { SearchField };
