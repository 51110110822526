import { AuthorizationParams } from '@auth0/auth0-spa-js';
import { Outlet, matchPath, useLocation } from 'react-router-dom';
import React, { FC, useEffect, useMemo } from 'react';

import { APP_ROUTES, INVITE_ROUTES, ROOT_ROUTES } from 'router/constants';
import { CurrentUserContextProvider } from 'context/CurrentUserContextProvider';
import { ErrorCode } from 'store/api/api.types';
import { initAuth, selectAuth } from 'store/auth/auth.slice';
import { isErrorResponse } from 'store/api/api.utils';
import { showError, showSuccess } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useLinkAccountMutation } from 'store/api/rootApi';
import LoaderView from 'components/views/LoaderView/LoaderView';
import ModalServiceWrapper from 'components/core/Modal/ModalServiceWrapper/ModalServiceWrapper';
import NotificationsContainer from 'components/dedicated/NotificationsContainer/NotificationsContainer';
import Text from 'components/core/Text/Text';
import auth0Client from 'services/auth0Client';
import modalService from 'services/modalService';
import router from 'router/router';

const Root: FC = () => {
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const { isAuthenticated, processing, user } = useAppSelector(selectAuth);
  const [linkAccount] = useLinkAccountMutation();
  const isCypressIntegrationTestRun = useMemo(
    () => window.Cypress && window.Cypress.env('CYPRESS_INTEGRATION_TEST_RUN'),
    [],
  );

  useEffect(() => {
    if (!isCypressIntegrationTestRun) {
      dispatch(initAuth());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && !user.email_verified) {
      const emailVerificationRequiredRoute = APP_ROUTES.EMAIL_VERIFICATION_REQUIRED.absolute;
      const isOnEmailVerificationRequiredView = !!matchPath(
        emailVerificationRequiredRoute,
        pathname,
      );
      if (!isOnEmailVerificationRequiredView) {
        router.navigate(emailVerificationRequiredRoute);
      }
    }
    const searchQuery = new URLSearchParams(search);

    if (searchQuery.has('linkAccount') && isAuthenticated) {
      const accountLinkHash = searchQuery.get('linkAccount');
      linkAccount({ hash: accountLinkHash! }).then(({ error }) => {
        if (!error) {
          showSuccess(
            <Text variant='bodyCopySmall'>
              <strong>Your accounts are now linked!</strong> You can sign in seamlessly using either
              authentication method.
            </Text>,
          );
        } else if (
          isErrorResponse(error) &&
          error.data.errors[0].code === ErrorCode.INVALID_OPERATION
        ) {
          showError(
            <Text variant='bodyCopySmall'>
              <strong>Your session to link accounts has expired.</strong> Please sign into your
              alternative sign-in method again to re-authenticate and complete linking your
              accounts.
            </Text>,
          );
        }
      });
    }

    // though auth0 has some native functionality for redirect handling
    // it would be more arduous/brittle to use given that the invite hashes are dynamic and
    // modifying the authorized redirect/callback URIs requires synchronizing
    // configuration between deployed environments
    // so here, we'll use our own redirect mechanism
    if (searchQuery.has('redirect_to') && isAuthenticated) {
      const redirectTo = searchQuery.get('redirect_to');
      router.navigate(redirectTo!);
    }
  }, [pathname, user, search, isAuthenticated, linkAccount]);

  if (!isCypressIntegrationTestRun && (processing === 'idle' || processing === 'pending')) {
    return <LoaderView />;
  }

  if (!isCypressIntegrationTestRun && !isAuthenticated) {
    const isOnSignupPage = !!matchPath(ROOT_ROUTES.SIGNUP.absolute, pathname);
    const isOnInviteAcceptPage = !!matchPath(INVITE_ROUTES.ACCEPT.absolute, pathname);
    const authorizationParams: AuthorizationParams = {
      screen_hint: isOnSignupPage || isOnInviteAcceptPage ? 'signup' : 'login',
    };
    if (isOnInviteAcceptPage) {
      authorizationParams.redirect_uri = `${window.location.protocol}//${window.location.host}?redirect_to=${encodeURI(pathname)}`;
    }
    auth0Client.loginWithRedirect({
      authorizationParams,
    });
    return <LoaderView />;
  }

  return (
    <CurrentUserContextProvider>
      <Outlet />
      <ModalServiceWrapper modalService={modalService} />
      <NotificationsContainer />
    </CurrentUserContextProvider>
  );
};

export default Root;
