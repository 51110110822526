import React, { FC } from 'react';

import { NoSearchResultsMessageProps } from 'components/dedicated/SearchField/SearchField.types';
import Box from 'components/core/Box/Box';
import NoResultsMessage from 'components/core/NoResultsMessage/NoResultsMessage';

export const NoSearchResultsMessage: FC<NoSearchResultsMessageProps> = ({ inputValue }) => {
  if (!inputValue) {
    return null;
  }
  return (
    <Box display='flex' justifyContent='flex-start'>
      <NoResultsMessage
        alignItems='flex-start'
        display='flex'
        header='No Matches Found'
        headerVariant='sectionHeaderStandard'
        justifyContent='flex-start'
        message='Try searching using different keywords or variations.'
      />
    </Box>
  );
};
