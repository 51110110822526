import { Tooltip as ReactTooltip } from 'react-tooltip';
import React, { FC, useMemo } from 'react';

import Box from 'components/core/Box/Box';
import ReactPortal from 'components/core/ReactPortal/ReactPortal';
import generateUid from 'utils/generateUid';
import isNumeric from 'utils/isNumeric';

import { TooltipProps } from './Tooltip.types';
import styles from './Tooltip.module.scss';

const Tooltip: FC<TooltipProps> = ({
  children,
  delayHide,
  delayShow,
  globalCloseEvents,
  isHidden = false,
  label,
  maxTooltipWidth = 48,
  offset,
  position,
  render,
  showArrow = false,
  tooltipChildren,
  tooltipTextAlign = 'center',
  variant = 'dark',
  ...props
}) => {
  const tooltipHash = useMemo(() => generateUid(), []);
  const tooltipId = `tooltip-${tooltipHash}`;
  return (
    <>
      <ReactPortal wrapperId={`tooltipPortal-${tooltipHash}`}>
        <ReactTooltip
          anchorSelect={`#${tooltipId}`}
          className={styles.tooltip}
          content={label}
          delayHide={delayHide}
          delayShow={delayShow}
          globalCloseEvents={globalCloseEvents}
          hidden={isHidden}
          noArrow={!showArrow}
          offset={offset}
          place={position}
          render={render}
          style={{
            maxWidth: isNumeric(maxTooltipWidth) ? `${maxTooltipWidth}rem` : maxTooltipWidth,
            textAlign: tooltipTextAlign,
          }}
          variant={variant}
        >
          {tooltipChildren}
        </ReactTooltip>
      </ReactPortal>
      <Box id={tooltipId} {...props}>
        {children}
      </Box>
    </>
  );
};

export default Tooltip;
