import React, { ReactElement, useEffect } from 'react';

import { ROOT_ROUTES } from 'router/constants';
import { selectAuth } from 'store/auth/auth.slice';
import { useAppSelector } from 'store/store';
import Box from 'components/core/Box/Box';
import Text from 'components/core/Text/Text';
import router from 'router/router';

const EmailVerificationRequiredView = (): ReactElement => {
  const { user } = useAppSelector(selectAuth);

  useEffect(() => {
    if (user && user.email_verified) {
      router.navigate(ROOT_ROUTES.APP.absolute);
    }
  }, [user]);

  return (
    <Box marginBottom={2} marginTop={12}>
      <Text align='center' variant='legacyTitle'>
        Confirm Your Email
      </Text>
      <Text align='center' marginTop={4}>
        Check your email for a verification link to activate your account.
      </Text>
    </Box>
  );
};

export default EmailVerificationRequiredView;
