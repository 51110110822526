import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table';
import React, { FC, useMemo } from 'react';

import { Identity, Organization, PaginationMode } from 'store/api/api.types';
import { useGetIdentitiesQuery } from 'store/api/platformApi';
import NoResultsMessage from 'components/core/NoResultsMessage/NoResultsMessage';
import PaginationBar from 'components/core/PaginationBar/PaginationBar';
import Spinner from 'components/core/Spinner/Spinner';
import Table from 'components/core/Table/Table';
import Text from 'components/core/Text/Text';
import usePaginationLinks from 'hooks/usePaginationLinks';
import usePaginationQuery from 'hooks/usePaginationQuery';

import IdentityBankAccounts from './IdentityBankAccounts/IdentityBankAccounts';
import IdentityDropdown from './IdentityDropDown/IdentityDropdown';
import IdentityStatus from './IdentityStatus/IdentityStatus';

const columnHelper = createColumnHelper<any>();

const columns = [
  columnHelper.accessor(row => row.name, {
    cell: info => <Text variant='bodyCopySmall'>{info.getValue()} </Text>,
    header: 'Name',
    id: 'name',
  }),
  columnHelper.accessor(row => row.country, {
    cell: info => <Text variant='bodyCopySmall'>{info.getValue()} </Text>,
    header: 'Country',
    id: 'country',
  }),
  columnHelper.accessor(row => row.bankAccounts, {
    cell: info => info.getValue(),
    header: 'Bank Accounts',
    id: 'bankAccounts',
  }),
  columnHelper.accessor(row => row.status, {
    cell: info => <Text variant='bodyCopySmall'>{info.getValue()}</Text>,
    header: 'Status',
    id: 'status',
  }),
  columnHelper.accessor(row => row.identityDetails, {
    cell: info => info.getValue(),
    header: '',
    id: 'more',
  }),
];

const identityName = (identity: Identity): string => {
  if (identity.businessName && identity.businessName !== ' ') {
    return identity.businessName;
  }
  return `${identity.firstName} ${identity.lastName} `;
};

const IdentitiesTable: FC<{ organizationId: Organization['id'] }> = ({ organizationId }) => {
  const { pageNumber, pageSize, handleOnLimitSelect } = usePaginationQuery();

  const {
    data: identitiesData,
    isUninitialized,
    isLoading,
    isFetching,
  } = useGetIdentitiesQuery({
    organizationId,
    params: {
      page: { mode: PaginationMode.Offset, number: pageNumber || undefined, size: pageSize.value },
      sort: 'name',
    },
  });

  const { handleOnNextPage, handleOnPreviousPage, handleOnSelectPage } = usePaginationLinks(
    PaginationMode.Offset,
    identitiesData?.links,
    pageSize.value,
  );

  const identities = useMemo(
    () =>
      [...(identitiesData?.data || [])].map(identity => ({
        bankAccounts: <IdentityBankAccounts identity={identity} organizationId={organizationId} />,
        country: identity.nationality,
        identityDetails: <IdentityDropdown identity={identity} organizationId={organizationId} />,
        name: identityName(identity),
        status: <IdentityStatus identity={identity} />,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationId, identitiesData?.data, pageNumber],
  );

  if (isUninitialized || isLoading) {
    return <Spinner />;
  }

  if (identities?.length === 0) {
    return <NoResultsMessage header='No Senders / Recipients Created' marginTop={16} />;
  }

  if (isLoading || isFetching) {
    return <Spinner flexGrow={1} size='large' />;
  }
  return (
    <>
      <Table
        options={{
          columns,
          data: identities as any,
          getCoreRowModel: getCoreRowModel(),
        }}
        showPagination={false}
      />
      <PaginationBar
        lastPageNumber={identitiesData?.links?.last}
        onLimitSelect={handleOnLimitSelect}
        onNextPage={handleOnNextPage}
        onPrevPage={handleOnPreviousPage}
        onSelectPage={handleOnSelectPage}
        pageNumber={pageNumber}
        pageSize={pageSize}
        resultsCount={identitiesData?.data.length}
      />
    </>
  );
};

export default IdentitiesTable;
