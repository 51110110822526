/* eslint-disable sort-keys-fix/sort-keys-fix */
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import React from 'react';

import { SendMoney, TransactionView } from 'components/views/app/organization/SendMoney';
import AccountLinkingRequiredView from 'components/views/app/AccountLinkingRequiredView/AccountLinkingRequiredView';
import ApiKeysView from 'components/views/app/organization/ApiKeysView/ApiKeysView';
import AppLayout from 'components/views/app/AppLayout/AppLayout';
import CreateOrganizationView from 'components/views/app/CreateOrganizationView/CreateOrganizationView';
import EmailVerificationRequiredView from 'components/views/app/EmailVerificationRequiredView/EmailVerificationRequiredView';
import IdentitiesView from 'components/views/app/organization/IdentitiesView/IdentitiesView';
import InviteAcceptView from 'components/views/app/invite/InviteAcceptView';
import InviteMembersView from 'components/views/app/InviteMembersView/InviteMembersView';
import LogoutView from 'components/views/LogoutView/LogoutView';
import MembersView from 'components/views/app/organization/MembersView/MembersView';
import OrganizationLayout from 'components/views/app/organization/OrganizationLayout/OrganizationLayout';
import OrganizationsView from 'components/views/app/OrganizationsView/OrganizationsView';
import PaymentReceiptView from 'components/views/app/organization/PaymentReceiptView/PaymentReceiptView';
import PendingVerificationView from 'components/views/app/organization/PendingVerificationView/PendingVerificationView';
import QuoteView from 'components/views/app/organization/SendMoney/QuoteView/QuoteView';
import Root from 'Root/Root';
import SettingsView from 'components/views/app/organization/SettingsView/SettingsView';
import TransactionsView from 'components/views/app/organization/TransactionsView/TransactionsView';
import UnderMaintenanceView from 'components/views/app/UnderMaintenanceView/UnderMaintenanceView';

import {
  APP_ROUTES,
  INVITES_ROUTES,
  INVITE_ROUTES,
  ORGANIZATIONS_ROUTES,
  ORGANIZATION_MEMBERS_ROUTES,
  ORGANIZATION_ROUTES,
  ORGANIZATION_TRANSACTIONS_ROUTES,
  PRINT_ROUTES,
  ROOT_ROUTES,
  SEND_MONEY_ROUTES,
} from './constants';
import { getRootAndWildcardRedirectionObjects } from './utils';

const routes: RouteObject[] = [
  {
    path: ROOT_ROUTES.APP.relative,
    children: [
      {
        path: APP_ROUTES.ORGANIZATIONS.relative,
        children: [
          {
            element: <AppLayout showOrganizationSwitcher={false} />,
            children: [
              {
                path: ORGANIZATIONS_ROUTES.CREATE_ORGANIZATION.relative,
                element: <CreateOrganizationView />,
              },
              {
                index: true,
                element: <OrganizationsView />,
              },
            ],
          },
          {
            path: ORGANIZATIONS_ROUTES.ORGANIZATION.relative,
            element: <AppLayout />,
            children: [
              {
                path: ORGANIZATION_ROUTES.PENDING_VERIFICATION.relative,
                element: <PendingVerificationView />,
              },
            ],
          },
          {
            path: ORGANIZATIONS_ROUTES.ORGANIZATION.relative,
            element: <OrganizationLayout />,
            children: [
              {
                path: ORGANIZATION_ROUTES.SETTINGS.relative,
                element: <SettingsView />,
              },
              {
                path: ORGANIZATION_ROUTES.API_KEYS.relative,
                element: <ApiKeysView />,
              },
              {
                path: ORGANIZATION_ROUTES.SEND_MONEY.relative,
                element: <SendMoney />,
                children: [
                  {
                    index: true,
                    element: <QuoteView />,
                  },
                  {
                    path: SEND_MONEY_ROUTES.TRANSACTION_DETAIL.relative,
                    element: <TransactionView />,
                  },
                ],
              },
              {
                path: ORGANIZATION_ROUTES.TRANSACTIONS.relative,
                children: [
                  {
                    path: ORGANIZATION_TRANSACTIONS_ROUTES.ALL.relative,
                    element: <TransactionsView />,
                  },
                  {
                    path: ORGANIZATION_TRANSACTIONS_ROUTES.PENDING.relative,
                    element: <TransactionsView />,
                  },
                  {
                    path: ORGANIZATION_TRANSACTIONS_ROUTES.COMPLETED.relative,
                    element: <TransactionsView />,
                  },
                  ...getRootAndWildcardRedirectionObjects(
                    ORGANIZATION_TRANSACTIONS_ROUTES.ALL.relative,
                  ),
                ],
              },
              {
                path: ORGANIZATION_ROUTES.SENDERS_RECIPIENTS.relative,
                element: <IdentitiesView />,
              },
              {
                path: ORGANIZATION_ROUTES.MEMBERS.relative,
                children: [
                  {
                    path: ORGANIZATION_MEMBERS_ROUTES.INVITE.relative,
                    element: <InviteMembersView />,
                  },
                  {
                    path: ORGANIZATION_MEMBERS_ROUTES.ACTIVE.relative,
                    element: <MembersView />,
                  },
                  {
                    path: ORGANIZATION_MEMBERS_ROUTES.INVITED.relative,
                    element: <MembersView />,
                  },
                  ...getRootAndWildcardRedirectionObjects(
                    ORGANIZATION_MEMBERS_ROUTES.ACTIVE.relative,
                  ),
                ],
              },
              ...getRootAndWildcardRedirectionObjects(ORGANIZATION_ROUTES.SEND_MONEY.relative),
            ],
          },
        ],
      },
      {
        path: APP_ROUTES.INVITES.relative,
        element: <AppLayout showOrganizationSwitcher={false} />,
        children: [
          {
            path: INVITES_ROUTES.INVITE.relative,
            children: [
              {
                path: INVITE_ROUTES.ACCEPT.relative,
                children: [
                  {
                    index: true,
                    element: <InviteAcceptView />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: APP_ROUTES.ACCOUNT_LINKING_REQUIRED.relative,
        element: <AppLayout showOrganizationSwitcher={false} />,
        children: [
          {
            index: true,
            element: <AccountLinkingRequiredView />,
          },
        ],
      },
      {
        path: APP_ROUTES.EMAIL_VERIFICATION_REQUIRED.relative,
        element: <AppLayout showOrganizationSwitcher={false} />,
        children: [
          {
            index: true,
            element: <EmailVerificationRequiredView />,
          },
        ],
      },
      ...getRootAndWildcardRedirectionObjects(APP_ROUTES.ORGANIZATIONS.absolute),
    ],
  },
  {
    path: ROOT_ROUTES.UNDER_MAINTENANCE.absolute,
    element: <AppLayout showOrganizationSwitcher={false} showUserMenu={false} />,
    children: [
      {
        index: true,
        element: <UnderMaintenanceView />,
      },
    ],
  },
  {
    path: ROOT_ROUTES.LOGOUT.relative,
    element: <LogoutView />,
  },
  {
    path: ROOT_ROUTES.PRINT.relative,
    children: [
      {
        path: PRINT_ROUTES.PAYMENT_RECEIPTS.relative,
        element: <PaymentReceiptView />,
      },
    ],
  },
  ...getRootAndWildcardRedirectionObjects(ROOT_ROUTES.APP.absolute),
];

export default createBrowserRouter([
  {
    path: ROOT_ROUTES.ROOT.absolute,
    element: <Root />,
    children: routes,
  },
]);
