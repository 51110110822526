import React, { forwardRef, useMemo } from 'react';
import cx from 'classnames';

import { checkmark } from 'components/core/Svg/icons';
import { white } from 'styles/_colors';
import Box from 'components/core/Box/Box';
import FormErrorMessage from 'components/core/Form/FormErrorMessage/FormErrorMessage';
import Svg from 'components/core/Svg/Svg';
import generateUid from 'utils/generateUid';

import CheckboxFieldProps from './CheckboxField.types';
import styles from './CheckboxField.module.scss';

function CheckboxField<T extends CheckboxFieldProps>(
  {
    className,
    dataTestId = '',
    error = '',
    id,
    isChecked,
    isDisabled = false,
    isRequired = false,
    label = '',
    name,
    onClick,
    onChange,
    value,
    ...rest
  }: T,
  ref,
) {
  const fieldId = useMemo(() => {
    return id || generateUid();
  }, [id]);

  return (
    <Box
      className={cx(
        styles.root,
        error && styles.hasError,
        isDisabled && styles.isDisabled,
        className,
      )}
      {...rest}
    >
      <input
        ref={ref}
        checked={isChecked}
        className={styles.input}
        data-testid={dataTestId}
        disabled={isDisabled}
        id={fieldId}
        name={name}
        onChange={onChange}
        onClick={onClick}
        required={isRequired}
        type='checkbox'
        value={value}
      />
      <div className={styles.checkbox}>
        <Svg className={styles.checkmark} color={white} img={checkmark} size={1.4} />
      </div>
      <div className={styles.contentWrapper}>
        <label className={styles.label} htmlFor={fieldId}>
          {label}
        </label>
        <FormErrorMessage
          className={styles.errorMessage}
          dataTestId={`${dataTestId}-error`}
          message={error}
        />
      </div>
    </Box>
  );
}

export default forwardRef(CheckboxField);
