import React, { FC } from 'react';

import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import Text from 'components/core/Text/Text';

import { NoResultsMessageProps } from './NoResultsMessage.types';

const NoResultsMessage: FC<NoResultsMessageProps> = ({
  header,
  message,
  button,
  className,
  headerVariant = 'sectionHeaderLarge',
  messageVariant = 'subCopyStandard',
  ...props
}) => {
  return (
    <Box
      alignItems='center'
      className={className}
      display='flex'
      flexDirection='column'
      justifyContent='center'
      maxWidth={62}
      {...props}
    >
      <Text align='center' variant={headerVariant}>
        {header}
      </Text>
      {message && (
        <Text align='center' marginTop={1.5} variant={messageVariant}>
          {message}
        </Text>
      )}
      {button && (
        <Button align='center' display='inline-block' marginTop={6} variant='primary' {...button} />
      )}
    </Box>
  );
};

export default NoResultsMessage;
