import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { ErrorCode } from 'store/api/api.types';
import { showError } from 'services/notificationService';

const getErrorMessageByErrorCode = (errorCode: ErrorCode): string | undefined => {
  switch (errorCode) {
    case ErrorCode.NODE_NOT_FOUND:
      return 'Due to ongoing system upgrades with our SWIFT partner, SWIFT transactions are temporarily disabled.';
    default:
      return undefined;
  }
};

export const showSpecificError = (error: FetchBaseQueryError): void => {
  const errorData: any = error.data;

  if (errorData?.errors && errorData?.errors?.length > 0) {
    const errorCode = errorData.errors[0].code;
    const errorMessage = getErrorMessageByErrorCode(errorCode);
    showError(errorMessage || errorData.errors[0].title);
  } else {
    showError('There was an error processing your request');
  }
};
