import { setUser } from '@sentry/react';
import { useLocation } from 'react-router-dom';
import React, { FC, ReactNode, useEffect, useMemo } from 'react';

import { APP_ROUTES } from 'router/constants';
import { ErrorCode, ErrorResponse } from 'store/api/api.types';
import { isFetchBaseQueryErrorType, isGenericErrorType } from 'store/api/api.utils';
import { useGetCurrentUserQuery } from 'store/api/rootApi';
import LoaderView from 'components/views/LoaderView/LoaderView';
import router from 'router/router';

import { CurrentUserContextType } from './CurrentUserContext.types';
import { isUserAllowedTo } from './CurrentUserContext.helpers';
import CurrentUserContext from './CurrentUserContext';

export const CurrentUserContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { isLoading, isUninitialized, error, data: currentUser } = useGetCurrentUserQuery();
  const { pathname } = useLocation();

  useEffect(() => {
    const { id } = currentUser || {};
    setUser(id ? { id } : null);
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser && isFetchBaseQueryErrorType(error) && !isGenericErrorType(error)) {
      const {
        data: { errors },
      } = error as ErrorResponse;
      // there's only one error in this context
      const errorObj = errors[0];
      if (errorObj.code === ErrorCode.ACCOUNT_LINKING_REQUIRED) {
        router.navigate(APP_ROUTES.ACCOUNT_LINKING_REQUIRED.absolute, {
          state: { hash: errorObj.meta?.accountLink.hash },
        });
      }
    }
  }, [error, pathname, currentUser]);

  const contextValue = useMemo(
    (): CurrentUserContextType => ({
      isUserAllowedTo: isUserAllowedTo(currentUser?.authorizations.combinedPolicies || []),
      user: currentUser,
    }),
    [currentUser],
  );

  return (
    <CurrentUserContext.Provider value={contextValue}>
      {isLoading || isUninitialized ? <LoaderView /> : children}
    </CurrentUserContext.Provider>
  );
};
