import { useLocation } from 'react-router-dom';
import React, { ReactElement, useEffect } from 'react';

import { ROOT_ROUTES } from 'router/constants';
import { selectAuth } from 'store/auth/auth.slice';
import { useAppSelector } from 'store/store';
import { useCurrentUser } from 'context/CurrentUserContext';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import Text from 'components/core/Text/Text';
import auth0Client from 'services/auth0Client';
import router from 'router/router';

const AccountLinkingRequiredView = (): ReactElement => {
  const { state } = useLocation();
  const { user: auth0User } = useAppSelector(selectAuth);
  const { user: appUser } = useCurrentUser();
  const { email } = auth0User || {};

  useEffect(() => {
    if (appUser) {
      router.navigate(ROOT_ROUTES.APP.absolute);
    }
  }, [appUser]);

  const handleInitAccountLinking = async () => {
    auth0Client.loginWithRedirect({
      authorizationParams: {
        prompt: 'login',
        redirect_uri: `${window.location.origin}?linkAccount=${state.hash}`,
      },
    });
  };

  return (
    <Box marginBottom={2} marginLeft='auto' marginRight='auto' marginTop={12} maxWidth={56}>
      <Text align='center' variant='legacyTitle'>
        Existing Account Already Registered
      </Text>
      <Text align='center' marginTop={4} variant='subCopySmall'>
        It looks like you already have an account with us using the email address{' '}
        <Text color='charcoalDarker' Tag='span' variant='labelSmall'>
          {email}
        </Text>
        . To link your email/password and Google account, please use your existing sign-in method.
      </Text>
      <Button align='center' marginTop={6} onClick={handleInitAccountLinking}>
        Sign In To Link Accounts
      </Button>
    </Box>
  );
};

export default AccountLinkingRequiredView;
