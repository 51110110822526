import React, { FC, useMemo } from 'react';

import { BoxProps } from 'components/core/Box/Box.types';
import { spacingBase } from 'styles/_layout';
import isNumeric from 'utils/isNumeric';

const applySpacing = (value?: number | string) =>
  value ? `calc(${value} * ${spacingBase})` : undefined;

const Box: FC<BoxProps> = ({
  alignItems,
  children,
  className,
  dataTestId,
  display,
  flexDirection,
  flexGrow,
  justifyContent,
  maxWidth,
  style = {},
  Tag = 'div',
  ...props
}) => {
  const {
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    gap,
    ...remainingProps
  } = props;
  // Padding and margin styles are computed based using spacing base value. The only exception is when a generic prop is provided with a string value (eg. "1rem 2rem") - then it's applied as-is.
  const paddingAndMarginStyle: React.CSSProperties = useMemo(() => {
    const computedStyle: React.CSSProperties = {};
    const directionalProps = ['margin', 'padding'];
    const directions = ['Bottom', 'Left', 'Right', 'Top'];

    // Apply margin and padding props. Generic props are applied first
    // - if they are not set, then directional props are applied.
    directionalProps.forEach(directionalProp => {
      if (props[directionalProp]) {
        computedStyle[directionalProp] = isNumeric(props[directionalProp])
          ? applySpacing(props[directionalProp])
          : props[directionalProp];
      } else {
        directions.forEach(direction => {
          const propName = `${directionalProp}${direction}`;
          if (props[propName]) {
            computedStyle[propName] = isNumeric(props[propName])
              ? applySpacing(props[propName])
              : props[propName];
          }
        });
      }
    });
    return computedStyle;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
  ]);

  const combinedStyle = {
    ...paddingAndMarginStyle,
    ...(display && { display }),
    ...(['flex', 'inline-flex'].includes(display || '') && {
      alignItems: alignItems || 'center',
      ...(flexDirection && { flexDirection }),
      ...(justifyContent && { justifyContent }),
    }),
    ...(gap && { gap: applySpacing(gap) }),
    ...(maxWidth && { maxWidth: isNumeric(maxWidth) ? `${maxWidth}rem` : maxWidth }),
    ...(flexGrow && { flexGrow }),
    ...style,
  };

  return (
    <Tag className={className} data-testid={dataTestId} style={combinedStyle} {...remainingProps}>
      {children}
    </Tag>
  );
};

export default Box;
